export type Usage = {
  orderId: number;
  orderCode: string;
  status: string;
  createdAt: string;
  serviceName: string;
  statuses: Array<{ status: string; createdAt: string }>;
};

export type PurchaseRequest = {
  purchaseRequestId?: number
  userInfoId?: number
  onlinePurchaseScheduleId?: number
  isReserved?: number
  announcementStep?: number
  createdAt?: string
  updatedAt?: string
};

export type SelectedItem = {
  purchaseItemId: number
  remarks: string
};

export type JobEnum = {
  EMPLOYEE: string
  TEMPORARY_EMPLOYEE: string
  PART_TIMER: string
  SELF_EMPLOYED: string
  CIVIL_SERVANT: string
  STUDENT: string
  HOUSEWIFE: string
  NO_JOBS: string
  OTHER: string
};

export type PickupTimeFrameEnum = {
  FROM_8_TO_13: string
  FROM_14_TO_16: string
  FROM_16_TO_18: string
  FROM_18_TO_21: string
};

export type PurchaseForm = {
  purchaseFormId?: number
  purchaseRequestId?: number
  createdAt?: string
  updatedAt?: string
};

// ご本人情報
export type UserInfo = {
  userName: string
  userSurname: string
  userNameKana: string
  userSurnameKana: string
  postcode: string
  address: string
  address1: string
  address2: string
  birthday: string
  email: string
  job: string
  detailJobOther: string
};

export type Room = {
  roomId: number
  canEnter: number
};

// お口座情報
export type AccountInfo = {
  bankName: string | null
  branchNumber: string | null
  branchName: string | null
  accountType: string | null
  accountNumber: string | null
  accountName: string | null
};

// 集荷ご希望日時
export type PickupDateTime = {
  pickupDate: string
  pickupTimeFrame: string
};

export type ReferralFriend = {
  usedDate: string
  friendName: string
};

export type BusinessInvoice = {
  isBusinessInvoice: boolean
  businessInvoiceRegistrationNumber: string
};


// 最新の入力済み買取情報取得API用
export type PurchaseDetailData = UserInfo & AccountInfo & PickupDateTime & BusinessInvoice & {
  purchaseDetailId: number
  purchaseFormId: number
  pickupAddress: string
  isPermittedMailMagazine: boolean
  createdAt: string
  updatedAt: string
};

export type CertainPeriodPurchaseSchedule = {
  time: string
  onlinePurchaseScheduleId: number
  bookableCount: number
};

export type CertainPeriodPurchaseSchedules = {
  [dateString: string]: CertainPeriodPurchaseSchedule[];
}

export const AttendantGender = {
  male        : 1,
  female      : 2,
  nonResponse : 9,
} as const;

export type AttendantGenderType = typeof AttendantGender[keyof typeof AttendantGender];

export type WorkableAttendant = {
  attendantId: number
  attendantName: string
  gender: AttendantGenderType
  introductoryEssay: string
  attendantImagePresignedUrl: string | null
};

export type PartialAddress = {
  postcode: string
  partialAddress: string
}
export type DataBank = {
  code: string
  name: string
  data: string
}

export type BranchBank = {
  code: string
  name: string
  halfWidthKana: string
  fullWidthKana: string
  hiragana: string
  data: any
  size: number
  limit: number
  hasNext: boolean
  hasPrev: boolean
  version: string
}

export type SelectedDataBank = {
  value: string
  label: string
  data: string
}

export type SelectedBranchBank = {
  value: string
  label: string
  data: string
}

export type SelectedCodeBank = {
  value: string
  label: string
  name: string
}

export type PickupAddress = {
  pickupPostcode: string
  pickupAddress: string
  pickupAddress1: string
  pickupAddress2: string
  pickupPref?: string
  pickupTown?: string
  pickupCity?: string
}

export type AddressWithPostcode = {
  postcode: string
  pref: string
  city: string
  town: string
  allAddress: string
}