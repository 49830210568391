export { basicTerm, laundryTerm } from './terms';

export const weekShortStrings: string[] = [
  '日',
  '月',
  '火',
  '水',
  '木',
  '金',
  '土',
];

export const prefectures: string[] = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const permittedLocations: string[] = [
  '1640000',
  '1650026',
  '1650022',
  '1650023',
  '1650031',
  '1640002',
  '1650032',
  '1650035',
  '1640011',
  '1640001',
  '1650025',
  '1650027',
  '1640003',
  '1640012',
  '1650024',
  '1650021',
  '1640014',
  '1650034',
  '1640013',
  '1650033',
  '1660000',
  '1660001',
  '1660004',
  '1670032',
  '1670021',
  '1680063',
  '1670035',
  '1660011',
  '1680064',
  '1680061',
  '1670051',
  '1670023',
  '1670043',
  '1680074',
  '1680082',
  '1660002',
  '1660003',
  '1670033',
  '1670022',
  '1680073',
  '1670054',
  '1670041',
  '1680071',
  '1680072',
  '1660016',
  '1660015',
  '1670042',
  '1670053',
  '1680065',
  '1680062',
  '1660013',
  '1670031',
  '1660014',
  '1670052',
  '1680081',
  '1670034',
  '1660012',
];

export const statusStringDict: {
  [key: string]: string;
} = {
  ordered: '集荷待ち',
  pick_up_outsourced: '集荷待ち',
  picked_up: '集荷済み',
  arrived: '集荷済み',
  billed: '見積もり確認',
  settlement_complete: '支払い済',
  supplier_rejected: '取扱不可',
  canceled: 'キャンセル',
  in_progress: '実施中',
  done: '実施中',
  delivery_outsourced: '実施中',
  shipped: '返送中',
  be_out: '不在',
  delivered: '配達済み',
};

export const LimitAPIBankCore : number = 2000;

// List of dates when the carrier is unavailable for delivery (holidays or non-working days).
export const excludedDates: string[] = [
  '12-25',
  '12-26',
  '12-27',
  '12-28',
  '12-29',
  '12-30',
  '12-31',
  '01-01',
  '01-02',
  '01-03',
  '01-04',
];

// Constant representing the maximum range of dates to consider for filtering valid dates.
export const maxDaysRangeDelivery : number = 18;

// Constant representing the delivery days limit from the current date
export const displayDaysLimitDelivery : number = 7;


export const roomStatus: {
  [key: string]: number;
} = {
  canEnter: 1,
  notEnter: 0,
};