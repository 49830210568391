import React from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import * as styles from './PickupDetailForm.scss';
import {
  AccountInfo,
  PickupDateTime,
  PickupTimeFrameEnum,
  UserInfo,
  BusinessInvoice,
  PickupAddress,
  AddressWithPostcode,
} from '../../../types/types';
import checkBox from "../../../assets/images/checkBox.png";
import checkedBox from "../../../assets/images/checkedBox.png";
import { excludedDates, maxDaysRangeDelivery, displayDaysLimitDelivery } from '../../../constants';

type Props = {
  accountInfo: AccountInfo,
  pickupTimeFrameEnum: PickupTimeFrameEnum | any,
  userInfo: UserInfo,
  pickupDateTime: PickupDateTime,
  submitDisabled: boolean,
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo>>,
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>,
  setPickupDateTime: React.Dispatch<React.SetStateAction<PickupDateTime>>,
  setPickupAddress: React.Dispatch<React.SetStateAction<string>>,
  getDatesFromTomorrowToNthDays: (maxDays: number) => Date[],
  getPartialAddressFromPostcodeAPI: (postcode: string) => Promise<AddressWithPostcode|null>,
  disabledOption: (value: string) => boolean,
  handlePurchaseDetailForm: () => Promise<void>,
  pickupAddressChecked: boolean,
  setPickupAddressChecked: React.Dispatch<React.SetStateAction<boolean>>,
  pickupAddressInfo: PickupAddress
  setPickupAddressInfo: React.Dispatch<React.SetStateAction<PickupAddress>>,
  businessInvoice: BusinessInvoice,
  setBusinessInvoice: React.Dispatch<React.SetStateAction<BusinessInvoice>>,
  loadingPartialAddressFromPostcodeAPI: boolean,
  setLoadingPartialAddressFromPostcodeAPI: React.Dispatch<React.SetStateAction<boolean>>,
  isPurchaseForm7: boolean,
}

// 集荷情報入力フォーム（form_type_id: 2）
const Component: React.FC<Props> = ({
  pickupDateTime,
  pickupTimeFrameEnum,
  userInfo,
  submitDisabled,
  setPickupDateTime,
  setPickupAddress,
  setUserInfo,
  getDatesFromTomorrowToNthDays,
  getPartialAddressFromPostcodeAPI,
  disabledOption,
  handlePurchaseDetailForm,
  pickupAddressChecked,
  setPickupAddressChecked,
  pickupAddressInfo,
  setPickupAddressInfo,
  businessInvoice,
  setBusinessInvoice,
  loadingPartialAddressFromPostcodeAPI,
  setLoadingPartialAddressFromPostcodeAPI,
  isPurchaseForm7,
}) => {
  const [isPostCodeError, setIsPostCodeError] = React.useState<boolean>(false);
  const [isPickupPostCodeError, setIsPickupPostCodeError] = React.useState<boolean>(false);
  // 集荷先住所に更新があった場合、DB保存用に値を合体する（チェックが外れたらリセット）
  React.useEffect(() => {
    setPickupAddress(
      pickupAddressChecked
        ? `〒${pickupAddressInfo.pickupPostcode} ${pickupAddressInfo.pickupAddress}`
        : ''
    )
  }, [pickupAddressChecked, pickupAddressInfo])


  return (
    <Container>
      <Helmet>
        <title>集荷情報入力</title>
      </Helmet>

      <h4 className={`pt-5 pb-3 text-center`}><b>集荷情報入力</b></h4>

      <div className="text-center mb-5">
        必須項目を全て入力し、「入力確認画面」へお進みください
      </div>

      <h5 className={styles.customSubTitle}>
        ご本人様情報
      </h5>
      <Form className="flex-fill" noValidate>
        <Form.Label className="font-weight-bold">
          お名前（本人確認書類と同一表記）<span className={styles.red}>必須</span>
        </Form.Label>
        <Form.Group as={Row} controlId="user_name">
          <Col sm="6">
            <Form.Label className="font-weight-bold">
            姓
            </Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="山田"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                  ...userInfo,
                  userSurname: e.target.value
                })}
                value={userInfo.userSurname}
                className={styles.formText}
                maxLength={50}
            />
          </Col>
          <Col sm="6">
            <Form.Label className="font-weight-bold">
              名
            </Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="太郎"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                  ...userInfo,
                  userName: e.target.value
                })}
                value={userInfo.userName}
                className={styles.formText}
                maxLength={50}
            />
          </Col>
        </Form.Group>
        <Form.Label className="font-weight-bold">
          お名前（全角フリガナ）<span className={styles.red}>必須</span>
        </Form.Label>
        <Form.Group as={Row} controlId="user_name">
          <Col sm="6">
            <Form.Label className="font-weight-bold">
              セイ
            </Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="ヤマダ"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                  ...userInfo,
                  userSurnameKana: e.target.value
                })}
                value={userInfo.userSurnameKana}
                className={styles.formText}
                maxLength={50}
            />
          </Col>
          <Col sm="6">
            <Form.Label className="font-weight-bold">
              メイ
            </Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="タロウ"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({
                  ...userInfo,
                  userNameKana: e.target.value
                })}
                value={userInfo.userNameKana}
                className={styles.formText}
                maxLength={50}
            />
          </Col>
        </Form.Group>
        <Form.Label className="font-weight-bold">
          ご住所<span className={styles.red}>必須</span>
        </Form.Label>
        <Form.Group as={Row} controlId="postcode">
          <Col sm="4" xs="12">
            <Form.Label className="font-weight-bold">
              郵便番号（ハイフンなし）
            </Form.Label>
          </Col>
          <Col sm="3" xs="6">
            <Form.Control
                required
                type="text"
                placeholder="00000000"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserInfo({
                    ...userInfo,
                    postcode: e.target.value,
                    address: '',
                    address1: '',
                    address2: ''
                  });
                }}
                onInput={() => { setIsPostCodeError(false) }}
                defaultValue={userInfo.postcode}
                className={styles.formText}
                maxLength={7}
                pattern="\d{7}"
            />
          </Col>
          <Col sm="3" xs="5">
            <div className="d-flex">
              <Button
                  variant="dark"
                  className={`${styles.customBackgroundButtonPostcode} flex-grow-1`}
                  onClick={async () => {
                    setLoadingPartialAddressFromPostcodeAPI(true)
                    const postcode = userInfo.postcode
                    const partialAddress = await getPartialAddressFromPostcodeAPI(postcode)
                    if(!partialAddress)
                    {
                      setIsPostCodeError(true)
                    }
                    else {
                      setUserInfo({
                        ...userInfo,
                        address: partialAddress?.allAddress ?? '',
                        address1: '',
                        address2: ''
                      });
                    }
                    setLoadingPartialAddressFromPostcodeAPI(false)
                  }}
                  disabled={loadingPartialAddressFromPostcodeAPI}
              >
                住所入力
              </Button>
            </div>
          </Col>
          <Col sm="2" xs="1"></Col>
        </Form.Group>
        <Form.Label className={`text-secondary ${styles.customSubTitleForAddressInput}`}>
          {userInfo?.address || (isPostCodeError ? '郵便番号が正しくありません。もう一度入力してください。' : '郵便番号を入力し、住所入力ボタンを押してください。') }
        </Form.Label>
        <Form.Group controlId="address">
          <Form.Control
              required
              type="hidden"
              placeholder="都道府県＆市区町村＆町域"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address: e.target.value })}
              value={userInfo?.address ?? ''}
              className={styles.formText}
              maxLength={255}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Control
              required
              type="text"
              placeholder="番地"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address1: e.target.value })}
              value={userInfo?.address1 ?? ''}
              className={styles.formText}
              maxLength={255}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Control
              required
              type="text"
              placeholder="マンション名・ビル名"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInfo({ ...userInfo, address2: e.target.value })}
              value={userInfo?.address2 ?? ''}
              className={styles.formText}
              maxLength={255}
          />
        </Form.Group>

        {!isPurchaseForm7 && (
            <>
        <h5 className={styles.customSubTitle}>
          集荷ご希望日時
        </h5>

        <Form.Group controlId="pickupDate">
          <Form.Label className="font-weight-bold">
            集荷日（翌日～1週間以内）<span className={styles.red}>必須</span>
          </Form.Label>
          <div className={styles.select}>
            <i
              className={`fa fa-caret-down ${styles.selectMark}`}
              aria-hidden="true"
            ></i>
            <Form.Control
              as="select"
              required
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPickupDateTime({ ...pickupDateTime, pickupDate: e.target.value, pickupTimeFrame: '' })}
              className={`
                ${styles.formSelect}
                ${pickupDateTime.pickupDate ? styles.selected : styles.unselected}
              `}
            >
              <option key="" value="">
                選択
              </option>
              {
                getDatesFromTomorrowToNthDays(maxDaysRangeDelivery).filter((date: Date) => {
                  const monthDay = `${(date.getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                  return !excludedDates.includes(monthDay);
                })
                    .slice(0, displayDaysLimitDelivery)
                    .map((date: Date, i: number) => (
                  <option
                    key={i}
                    value={`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`.replace(/\n|\r/g, '')} // ex: "2021-06-10"
                  >
                    {/* ex: 6月10日 */}
                    {`${(date.getMonth() + 1)}月${date.getDate()}日`.replace(/\n|\r/g, '')}
                  </option>
                ))
              }
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="pickupTimeFrame">
          <Form.Label className="font-weight-bold">
            集荷時間（①～④でご選択ください。）<span className={styles.red}>必須</span>
          </Form.Label>
          <div className={styles.select}>
            <i
              className={`fa fa-caret-down ${styles.selectMark}`}
              aria-hidden="true"
            ></i>
            <Form.Control
              as="select"
              required
              value={pickupDateTime.pickupTimeFrame}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPickupDateTime({ ...pickupDateTime, pickupTimeFrame: e.target.value })}
              className={`
                ${styles.formSelect}
                ${pickupDateTime.pickupTimeFrame ? styles.selected : styles.unselected}
              `}
            >
              <option key="" value="">
                選択
              </option>
              {
                pickupDateTime.pickupDate &&
                Object.keys(pickupTimeFrameEnum).map(key => (
                  <option key={key} value={key} disabled={disabledOption(pickupTimeFrameEnum[key])}>
                    {pickupTimeFrameEnum[key]}
                  </option>
                ))
              }
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <div className={styles.covenantRadioBox}>
            <img
                className={styles.covenantCheckBox}
                src={pickupAddressChecked ? checkedBox : checkBox}
                onClick={() => {
                  if (pickupAddressChecked)
                  {
                    setPickupAddressInfo({
                      ...pickupAddressInfo,
                      pickupPostcode: '',
                      pickupAddress: '',
                      pickupAddress1: '',
                      pickupAddress2: '',
                      pickupPref: '',
                      pickupCity: '',
                      pickupTown: '',
                    })
                  }
                  setPickupAddressChecked(!pickupAddressChecked)}
                }
            />
            <span>集荷先を別に指定</span>
          </div>
          <Form.Label className="font-weight-bold">
            <small>登録住所と相違住所の場合はご記入ください</small>
          </Form.Label>
        </Form.Group>

        {pickupAddressChecked && (
          <>
            <Form.Group as={Row} controlId="pickup_postcode">
              <Col sm="4" xs="12">
                <Form.Label className="font-weight-bold">
                  郵便番号（ハイフンなし）
                </Form.Label>
              </Col>
              <Col sm="3" xs="6">
                <Form.Control
                    required={pickupAddressChecked}
                    type="text"
                    placeholder="00000000"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPickupAddressInfo({
                        ...pickupAddressInfo,
                        pickupPostcode: e.target.value,
                        pickupAddress: '',
                        pickupAddress1: '',
                        pickupAddress2: '',
                      })

                    }}
                    onInput={() => { setIsPickupPostCodeError(false) }}
                    defaultValue={pickupAddressInfo.pickupPostcode}
                    className={styles.formText}
                    maxLength={7}
                    pattern="\d{7}"
                />
              </Col>
              <Col sm="3" xs="5">
                <div className="d-flex">
                  <Button
                      variant="dark"
                      className={`${styles.customBackgroundButtonPostcode} flex-grow-1`}
                      onClick={async () => {
                        setLoadingPartialAddressFromPostcodeAPI(true)
                        const postcode = pickupAddressInfo.pickupPostcode
                        const partialAddress = await getPartialAddressFromPostcodeAPI(postcode)
                        if(!partialAddress)
                        {
                          setIsPickupPostCodeError(true)
                        }
                        setPickupAddressInfo({
                          ...pickupAddressInfo,
                          pickupAddress: partialAddress?.allAddress ?? '',
                          pickupPref: partialAddress?.pref ?? '',
                          pickupCity: partialAddress?.city ?? '',
                          pickupTown: partialAddress?.town ?? '',
                          pickupAddress1: '',
                          pickupAddress2: ''
                        });
                        setLoadingPartialAddressFromPostcodeAPI(false)
                      }}
                      disabled={loadingPartialAddressFromPostcodeAPI}
                  >
                    住所入力
                  </Button>
                </div>
              </Col>
              <Col sm="2" xs="1"></Col>
            </Form.Group>
            <Form.Label className={`text-secondary ${styles.customSubTitleForAddressInput}`}>
              {pickupAddressInfo?.pickupAddress || (isPickupPostCodeError ? '郵便番号が正しくありません。もう一度入力してください。' : '郵便番号を入力し、住所入力ボタンを押してください。') }
            </Form.Label>
            <Form.Group controlId="pickup_partial_address">
              <Form.Control
                  required
                  type="hidden"
                  placeholder="都道府県＆市区町村＆町域"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPickupAddressInfo({ ...pickupAddressInfo, pickupAddress: e.target.value })}
                  value={pickupAddressInfo?.pickupAddress ?? ''}
                  className={styles.formText}
                  maxLength={255}
              />
            </Form.Group>
            <Form.Group controlId="pickup_partial_address1">
              <Form.Control
                  required
                  type="text"
                  placeholder="番地"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPickupAddressInfo({ ...pickupAddressInfo, pickupAddress1: e.target.value })}
                  value={pickupAddressInfo?.pickupAddress1 ?? ''}
                  className={styles.formText}
                  maxLength={255}
              />
            </Form.Group>
            <Form.Group controlId="pickup_partial_address2">
              <Form.Control
                  required
                  type="text"
                  placeholder="マンション名・ビル名"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPickupAddressInfo({ ...pickupAddressInfo, pickupAddress2: e.target.value })}
                  value={pickupAddressInfo?.pickupAddress2 ?? ''}
                  className={styles.formText}
                  maxLength={255}
              />
            </Form.Group>
          </>
        )}

        </>
        )}

        <Form.Label className="font-weight-bold">適格請求書発行事業者ですか（個人でご利用のお客様は「いいえ」をお選びください)</Form.Label>
        <Form.Group className="pt-2">
          <div className={styles.covenantRadioBox}>
            <img
                className={styles.covenantCheckBox}
                src={businessInvoice.isBusinessInvoice ? checkBox : checkedBox}
                onClick={() => {
                  setBusinessInvoice(prevState => ({
                    ...prevState,
                    isBusinessInvoice: false,
                    businessInvoiceRegistrationNumber: '',
                  }));
                }}
            />
            <span>いいえ</span>
            <img
                className={styles.covenantCheckBox}
                src={businessInvoice.isBusinessInvoice ? checkedBox : checkBox}
                onClick={() => {
                  setBusinessInvoice(prevState => ({
                    ...prevState,
                    isBusinessInvoice: true,
                    businessInvoiceRegistrationNumber: '',
                  }));
                }}
            />
            <span>はい</span>
          </div>
        </Form.Group>
        {businessInvoice.isBusinessInvoice && (
            <Form.Group>
              <Form.Label className="font-weight-bold">登録番号を入力してください。</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="例：T1234567891234"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBusinessInvoice({
                    ...businessInvoice,
                    businessInvoiceRegistrationNumber: e.target.value
                  })}
                  value={businessInvoice.businessInvoiceRegistrationNumber ?? ''}
                  className={styles.formText}
                  maxLength={14}
              />
            </Form.Group>
        )}

        <Button
          // IDはGTMでのトラッキング用に付与している
          id="btn__purchase-details--confirm"
          type="button"
          className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.customButton} ${styles.button}`}
          variant="light"
          disabled={submitDisabled}
          onClick={handlePurchaseDetailForm}
        >
          入力確認画面へ
        </Button>
      </Form>
    </Container>
  )
}

export default Component;
