import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import { SelectedItem, Room } from '../../../types/types';

import * as styles from './SelectPurchaseItemForm.scss';
import checkBox from '../../../assets/images/checkBox.png';
import checkedBox from '../../../assets/images/checkedBox.png';
import {
    MAX_QTY_FOR_30_MINUTES_FRAME,
    MAX_QTY_FOR_60_MINUTES_FRAME,
    MAX_QTY_FOR_90_MINUTES_FRAME,
    MAX_QTY_FOR_120_MINUTES_FRAME
} from '../../../enums/SelectablePurchaseItemsQty';
import { roomStatus } from "../../../constants";

type Props = {
  selectedItems: SelectedItem[];
  rooms: Room[];
};

const Component: React.FC<Props> = ({
  selectedItems,
  rooms,
}) => {
  const history = useHistory();

  const [remarks, setRemarks] = React.useState<string>(() => {
    if (selectedItems.length === 0) {
      return ''
    }

    return selectedItems
      .map(({ remarks }) => remarks)
      .filter((item) => !!item)
      .join('\n')
  });

  const [
    selectedQty,
    setSelectedQty
  ] = React.useState<number>(
    selectedItems.length > 0
      ? selectedItems.length
      : MAX_QTY_FOR_60_MINUTES_FRAME
  );

  const [covenantChecked, setCovenantChecked] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);

  // 持ち込み査定品予定数が選択されていない時、特約がチェックされていても送信できないようにする
  React.useEffect(() => {
    setSubmitDisabled(selectedQty === 0 ? true : !covenantChecked)
  }, [covenantChecked, selectedQty]);

  // Check display conditions 90 minutes and 120 minutes
  const canDisplay90Minutes = rooms?.some((room) => room.roomId === 9 && room.canEnter === roomStatus.canEnter);
  const canDisplay120Minutes = rooms?.some((room) => room.roomId === 10 && room.canEnter === roomStatus.canEnter);

  const openCovenantUrl = () => {
    liff.openWindow({
      url: 'https://brandear.jp/ct/kiyaku_riyou?pid_bellkiyaku#tab3'
    })
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitDisabled(true)

    if (!selectedQty) {
      return alert('持込予定品が入力されていません');
    }

    // 予約変更の場合は選択済みの同一接客員希望有無と接客員IDを取得(nullの場合は通常フロー)
    const isDesiredSameAttendantParam = qs.parse(location.search).isDesiredSameAttendant as null | '0' | '1'
    const desiredAttendantId = Number(qs.parse(location.search).desiredAttendantId)

    // 商品情報を配列に変えてスケジュール選択画面に送信
    const inputtedRemarks: (string | null)[] = Array.from({ length: selectedQty }, () => null);
    inputtedRemarks[0] = remarks;

    return history.push(
      '/purchase-schedule',
      {
        selectedQty,
        remarks: inputtedRemarks,
        defaultIsDesiredSameAttendant: Boolean(Number(isDesiredSameAttendantParam)), // 文字列の0をfalsyに扱うためにNumber()を経由
        desiredAttendantId,
      }
    );
  }

  return (
    <Form className="flex-fill" onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="d" className="mt-3 mb-5">
        <h5 className={`${styles.boldText} mb-3`}>
          ご希望のコース<sup className={`${styles.textLightRed} ${styles.supText}`}>※必須</sup>
        </h5>
        <fieldset className={styles.radioFieldset}>
          <input
            id="long-course"
            className={styles.radioInput}
            type="radio"
            name="course-select"
            value={MAX_QTY_FOR_60_MINUTES_FRAME}
            checked={selectedQty === MAX_QTY_FOR_60_MINUTES_FRAME}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.persist()
              setSelectedQty(parseInt(e.target.value, 10));
            }}
          />
          <label
            className={`
              ${styles.radioLabel} ${styles.radioLeftLabel}
              ${selectedQty === MAX_QTY_FOR_60_MINUTES_FRAME ? styles.checked : ''}
            `}
            htmlFor="long-course">
            <i className="fa fa-check fa-1 d-none"></i>
            60分({MAX_QTY_FOR_30_MINUTES_FRAME + 1}〜{MAX_QTY_FOR_60_MINUTES_FRAME}点)
          </label>
          <input
            id="short-course"
            className={styles.radioInput}
            type="radio"
            name="course-select"
            value={MAX_QTY_FOR_30_MINUTES_FRAME}
            checked={selectedQty === MAX_QTY_FOR_30_MINUTES_FRAME}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.persist()
              setSelectedQty(parseInt(e.target.value, 10));
            }}
          />
          <label
            className={`
              ${styles.radioLabel}
              ${selectedQty === MAX_QTY_FOR_30_MINUTES_FRAME ? styles.checked : ''}
            `}
            htmlFor="short-course">
            <i className="fa fa-check fa-1 d-none"></i>
            30分(1〜{ MAX_QTY_FOR_30_MINUTES_FRAME }点)
          </label>
          {/* 90分 */}
          {canDisplay90Minutes && (
          <>
          <input
            id="course-90"
            className={styles.radioInput}
            type="radio"
            name="course-select"
            value={MAX_QTY_FOR_90_MINUTES_FRAME}
            checked={selectedQty === MAX_QTY_FOR_90_MINUTES_FRAME}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
               e.persist()
               setSelectedQty(parseInt(e.target.value, 10));
            }}
          />
          <label
            className={`
              ${styles.radioLabel} ${styles.radioLeftLabel}
              ${selectedQty === MAX_QTY_FOR_90_MINUTES_FRAME ? styles.checked : ''}
            `}
            htmlFor="course-90">
              <i className="fa fa-check fa-1 d-none"></i>
              90分(7〜{MAX_QTY_FOR_90_MINUTES_FRAME}点)
          </label>
          </>
          )}
          {/* 120分 */}
          {canDisplay120Minutes && (
          <>
          <input
            id="course-120"
            className={styles.radioInput}
            type="radio"
            name="course-select"
            value={MAX_QTY_FOR_120_MINUTES_FRAME}
            checked={selectedQty === MAX_QTY_FOR_120_MINUTES_FRAME}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
               e.persist()
               setSelectedQty(parseInt(e.target.value, 10));
            }}
          />
          <label
            className={`
              ${styles.radioLabel}
              ${selectedQty === MAX_QTY_FOR_120_MINUTES_FRAME ? styles.checked : ''}
            `}
             htmlFor="course-120">
             <i className="fa fa-check fa-1 d-none"></i>
             120分(10〜{MAX_QTY_FOR_120_MINUTES_FRAME}点)
          </label>
          </>
          )}
        </fieldset>
        <div className={`${styles.supplementaryExplanation}`}>
          <p className="mb-0">※予約時間内であれば{MAX_QTY_FOR_120_MINUTES_FRAME + 1}点以上でも査定可能です。</p>
        </div>
      </Form.Group>


      <Form.Group controlId="h" className="mb-1">
        <h5 className={`${styles.boldText} mb-3`}>
         お品物情報<sup className={`${styles.supText} ${styles.textPlaceholder}`}>※任意</sup>
        </h5>
        <div className={`${styles.listSupplementaryExplanation}`}>
          <p className="mb-0">
           ※ご記入頂くことでよりスムーズな査定が可能です。
          </p>
        </div>
        <Form.Control
          as="textarea"
          rows={3}
          defaultValue={remarks}
          placeholder="ルイヴイトン・バッグ
エルメス・財布"
          className={`${styles.formText}`}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            e.persist()
            setRemarks(e.target.value);
          }}
        />
      </Form.Group>

      <div>
        <img
          className={styles.covenantCheckBox}
          src={covenantChecked ? checkedBox : checkBox}
          onClick={() => setCovenantChecked(!covenantChecked)}
        />
        <a href="#" className={styles.covenantUrl} onClick={openCovenantUrl}>ブランディアBell特約 </a>
        <span>に同意します。</span>
      </div>

      <Button
        // IDはGTMでのトラッキング用に付与している
        id="btn__order--confirm"
        type="submit"
        className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
        variant="light"
        disabled={submitDisabled}
      >
        日時選択へ進む
      </Button>

      <div className="text-center my-4">
        <Button
          className={styles.backBtn}
          type="button"
          variant="link"
          onClick={
            // 戻り先が無い場合はliffを閉じる
            history.action === 'POP'
              ? liff.closeWindow
              : history.goBack
          }
        >
          戻る
        </Button>
      </div>
    </Form>
  );
};

export default Component;
